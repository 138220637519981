import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { usePageName } from 'react-page-name';
import axios from "axios";
import "./view.scss"
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import moment from "moment";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'
import "./audioPlayer.scss"

const View = () => {

    const location = useLocation();

    const postSlug = location.pathname.split("/")[2];

    const [post, setPost] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPost = async () => {
            try {
                const res = await axios.get(`https://api.augschoell.com/wp-json/wp/v2/kompositionen?slug=${postSlug}`)
                setPost(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPost()
    }, [postSlug])

    usePageName( isLoading ? "Laden | Jakob Augschöll" : `${post.title.rendered} | Jakob Augschöll`);

    return (
        <div className="view">
            { isLoading ? <div className="loading"/> : <>
                <div className="header">
                    <div className="container">
                        <span className="postTyp">{post.acf.type}</span>
                        <span className="postKategorie">{post.acf.category}</span>
                        <h3>{post.title.rendered}</h3>
                        <span className="postDatum">{moment(post.acf.date).format('YYYY')}</span>
                        {!!post.acf.audio && <AudioPlayer
                            src={post.acf.audio}
                        />}
                    </div>
                </div>
                <div className="inhalt">
                    <div className="container">
                        <div className="infos">
                            {!!post.acf.pdf && <div className="pdf" onClick={() => window.open(post.acf.pdf, "_blank")}>
                                <AudioFileOutlinedIcon className="left" />
                                <span className="right">PDF</span>
                            </div>}
                            {!!post.acf.grad && <div className="grade">
                                <span className="left">Grad</span>
                                <span className="right">{post.acf.grad}</span>
                            </div>}
                            {!!post.acf.besetzung && <div className="besetzung">
                                <span className="left">Besetzung</span>
                                <span className="right">{post.acf.besetzung}</span>
                            </div>}
                            {post.acf.link && <div className="link" onClick={() => window.open(post.acf.link, "_blank")}>
                                <span className="left">Kaufen bei</span>
                                <span className="right">{post.acf.link.split("/")[2]}</span>
                            </div>}
                            {(!post.acf.link && post.acf.type !== "News") && <Link className="link" to={"anfrage"}>
                                <span className="right">Anfragen</span>
                            </Link>}
                        </div>
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                    </div>
                </div>
            </>}
        </div>
    )
};

export default View