import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import "../biografie/biografie.scss"
import axios from "axios";

const Biografie = () => {

    usePageName(`Biografie | Jakob Augschöll`);

    const [data, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get("https://api.augschoell.com/wp-json/wp/v2/pages/2/")
                setPosts(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [])

    return (
        <div className="biografie">
            {
                isLoading
                    ? <div className="loading" /> : <>
                        <div className="container">
                            <h2>{data.title.rendered}</h2>
                            <div className="bioContent">
                                <div className="left">
                                    <img className="top" src={data.acf.image_1} alt="" />
                                    <img className="bottom" src={data.acf.image_2} alt="" />
                                </div>
                                <div className="right">
                                    <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                                    <img src={data.acf.image_3} alt="" />
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
};

export default Biografie