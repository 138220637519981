import React from "react";
import "./impressum.scss"

const Impressum = () => {
    return (
        <div className="impressum">
            <div className="container">
                <h2>Impressum</h2>
                <div className="impressumContent">
                    <h3>Jakob Augschöll</h3>
                    <p>
                        Ahrner Straße 17<br/>
                        39030 - Luttach<br/>
                        BZ, Italien
                    </p>
                    <p>
                        Steuernummer: GSCJKB98P07B220E<br/>
                        E-Mail: info@augschoell.com<br/>
                        Tel: +39 348 184 3296
                    </p>
                    <h3>Copyright Hinweis</h3>
                    <p>Die bereitgestellten Informationen auf dieser Website wurden sorgfältig geprüft und werden regelmäßig vom Inhaber der Webseite aktualisiert. Es kann keine Garantie dafür übernommen werden, dass die auf dieser Website bereitgestellten Informationen vollständig, richtig oder auch aktuell sind. Zudem entfällt die Verantwortung für Links von Dritter, die von der Website erreichbar sind. Für Inhalte der verlinkten Seiten haftet der Anbieter der jeweiligen Webseite. Sämtliche Beiträge die Sie auf dieser Homepage finden, sind urheberrechtlich geschützt.</p>
                </div>
            </div>
        </div>
    )
};

export default Impressum