import React from "react";
import "../footer/footer.scss"
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="contactBanner">
                    <div className="telBox" onClick={() => window.location = 'tel:+393481843296'}>
                        <LocalPhoneOutlinedIcon />
                        <div className="right">
                            <span className="desc">Telefon</span>
                            <span className="value">+39 348 184 3296</span>
                        </div>
                    </div>
                    <div className="emailBox" onClick={() => window.location = 'mailto:info@augschoell.com'}>
                        <div className="contact">Kontakt</div>
                        <EmailOutlinedIcon />
                        <div className="right">
                            <span className="desc">E-Mail</span>
                            <span className="value">info@augschoell.com</span>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <Link to={"/"}>
                        <h2>Jakob Augschöll</h2>
                    </Link>
                    <div className="right">
                        <span>©{new Date().getFullYear()} Jakob Augschöll</span>
                        <NavLink to={"/datenschutz"}>Datenschutz</NavLink>
                        <NavLink to={"/impressum"}>Impressum</NavLink>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer