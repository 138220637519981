import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../posts/posts.scss"
import { Link } from "react-router-dom";
import moment from "moment";

const Posts = () => {

    const [posts, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get("https://api.augschoell.com/wp-json/wp/v2/kompositionen?per_page=8")
                setPosts(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [])

    return (
        <div className="posts">
            {isLoading ? <div className="loading"/> : posts.map(posts => (
                <Link className="postContent" to={`/post/${posts.slug}`} key={posts.id}>
                    <div className="inline">
                        <span className="postTyp">{posts.acf.type}</span>
                        <span className="postKategorie">{posts.acf.category}</span>
                        <h3>{posts.title.rendered}</h3>
                        <span className="postDatum">{moment(posts.acf.year).format("YYYY")}</span>
                        <span className="postBg">{posts.acf.type[0]}</span>
                    </div>
                </Link>
            ))}
        </div>
    )
};

export default Posts